import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Images
import Chevron from 'img/chevronright.inline.svg'
import NavLink from 'components/shared/NavLink'
import { motion } from 'framer-motion'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpPost,
    allWpCategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.blogQueryQuery>(graphql`
    query blogQuery {
      allWpPost(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
      allWpCategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  return (
    <section>
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={allWpCategory.edges}
        id="tegelmeester-blog"
        limit={Number(fields.limit)}
      >
        <div className="py-lg-5 py-3">
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  const [currentIndex, setCurrentIndex] = useState(-1)

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10">
          <ParseContent content={fields.description} />
          <div className="row">
            {blogBink.posts.map((post, index) => {
              const { node }: any = post

              return (
                <motion.div
                  key={post.node.id}
                  className="col-sm-6 mt-lg-5 mt-5"
                  onHoverStart={() => setCurrentIndex(index)}
                  onHoverEnd={() => setCurrentIndex(-1)}
                >
                  <BlogGridPost node={node} hovered={currentIndex === index} />
                </motion.div>
              )
            })}
          </div>
        </div>
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-lg-5 mt-3 text-center">
          <ButtonPrimary calendar={false} to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

interface BlogGridPostProps {
  hovered: boolean
  node: {
    id: string
    title: string
    uri: string
    recap: {
      excerpt: string
      thumbnail: any
    }
  }
}

const Image = styled(Plaatjie)``

const BlogGridPostExcerpt = styled(ParseContent)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;

  & p {
    font-weight: ${({ theme }) => theme.font.weight.extraLight};
  }
`

const Post = styled.div`
  position: relative;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);

  & h2 {
    font-size: ${({ theme }) => theme.font.size.big};
    color: ${({ theme }) => theme.color.dark};
    margin-bottom: 1rem;
  }

  & svg {
    width: 20px;
    height: 18px;
    & path {
      fill: ${({ theme }) => theme.color.secondary};
    }
  }

  @media (min-width: 576px) {
    margin-top: -4rem;
    margin-right: -2rem;
    margin-left: 3rem;
  }
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, hovered }) => (
  <NavLink to={node.uri}>
    <div className="mx-lg-5">
      <div className="overflow-hidden">
        <motion.div
          initial={{ scale: 1.1 }}
          animate={hovered ? { scale: 1.0 } : { scale: 1.1 }}
        >
          <Image image={node.recap.thumbnail} alt="" />
        </motion.div>
      </div>

      <Post className="p-4">
        <h2>{node.title}</h2>
        <BlogGridPostExcerpt content={node.recap.excerpt} />
        <div className="d-flex justify-content-end mt-2">
          <motion.div
            initial={{ x: -100 }}
            animate={hovered ? { x: 0 } : { x: -7 }}
          >
            <Chevron />
          </motion.div>
        </div>
      </Post>
    </div>
  </NavLink>
)

export default Blog
