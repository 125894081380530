import React, { useState } from 'react'

// Third party

import styled, { css } from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkPosts,
} from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'
import Lightbox from 'react-awesome-lightbox'

// Third party CSS
import 'react-awesome-lightbox/build/style.css'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'
import ButtonTertiary from 'components/elements/Buttons/ButtonTertiary'

// Images
import Chevron from 'img/chevrondown.inline.svg'

const Section = styled.section<{ elementType: string }>`
  ${(props) =>
    props.elementType === 'latest' &&
    css`
      background-color: ${({ theme }) => theme.color.primary};
    `}
`

const Row = styled.div<{ elementType: string }>`
  ${(props) =>
    props.elementType === 'latest' &&
    css`
      margin-top: -14rem;
    `}
`

const LightboxWrapper = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
`

interface ProjectsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Projects: React.FC<ProjectsProps> = ({ fields }) => {
  const {
    allWpProject,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.projectsQueryQuery>(graphql`
    query projectsQuery {
      allWpProject(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalProjectFragment
          }
        }
      }
    }
  `)

  const projects: unknown = allWpProject.edges

  return (
    <Section
      elementType={fields.elementtype || 'overview'}
      className="pt-4 pb-5"
    >
      <h2 className="mt-5 text-center">
        Op dit moment zijn we hard bezig om ons portfolio aan projecten te
        vullen met foto's.
      </h2>
      <p className="text-center">Houd deze pagina in de gaten voor updates</p>
      {/* <BlogBink
        posts={projects as BlogBinkPosts}
        id="tegelmeester-projects"
        limit={Number(fields.limit)}
      >
        <div className="py-lg-5 py-3">
          <BlogGrid fields={fields} />
        </div>
      </BlogBink> */}
    </Section>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  const [openIndex, setOpenIndex] = useState<null | number>(null)
  const [hovered, setHovered] = useState(-1)
  const images: any = []

  return (
    <>
      <div className="container">
        <Row elementType={fields.elementtype || 'overview'}>
          <ParseContent content={fields.description} className="mb-5" />
          <div className="row">
            {blogBink.posts.map((post, index) => {
              const { node }: any = post

              if (openIndex === index) {
                node.recap.gallery?.forEach((image: any) => {
                  images.push({
                    url: image?.localFile?.childImageSharp?.original?.src,
                    sizes: image?.title,
                  })
                })
              }

              return (
                <motion.div className="col-6 col-sm-4 mb-4">
                  <Post
                    item={node}
                    index={index}
                    setOpenIndex={setOpenIndex}
                    setHovered={setHovered}
                    hovered={hovered}
                  />
                </motion.div>
              )
            })}
          </div>
        </Row>
        {fields.elementtype === 'latest' && (
          <div className="d-flex justify-content-center mt-lg-5 mt-3">
            <ButtonSecondary to="/projecten">
              BEKIJK ALLE PROJECTEN
            </ButtonSecondary>
          </div>
        )}

        {blogBink.showMoreButton && fields.elementtype !== 'latest' && (
          <div className="mt-5 text-center">
            <ButtonTertiary to="#" isCustom>
              <BlogBinkButton className="d-flex align-items-center justify-content-between">
                <span>{fields.loadmoretext}</span>
                <Chevron />
              </BlogBinkButton>
            </ButtonTertiary>
          </div>
        )}
      </div>
      <AnimatePresence>
        {openIndex !== null && (
          <LightboxWrapper
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
              duration: 0.2,
            }}
          >
            {images.length > 1 && (
              <Lightbox
                image={images[0].url}
                images={images}
                startIndex={0}
                onClose={() => {
                  setOpenIndex(null)
                }}
                doubleClickZoom={2}
                showTitle={false}
              />
            )}

            {images.length < 2 && (
              <Lightbox
                image={images[0].url}
                startIndex={0}
                onClose={() => {
                  setOpenIndex(null)
                }}
                doubleClickZoom={2}
                showTitle={false}
              />
            )}

            {console.log('img', images)}
          </LightboxWrapper>
        )}
      </AnimatePresence>
    </>
  )
}

const Image = styled(Plaatjie)`
  aspect-ratio: 1/1;
`

interface PostProps {
  item: any
  index: number
  // eslint-disable-next-line no-unused-vars
  setOpenIndex: (index: number) => void
  // eslint-disable-next-line no-unused-vars
  setHovered: (index: number) => void
  hovered: number
}

const Post: React.FC<PostProps> = ({
  item,
  index,
  setOpenIndex,
  setHovered,
  hovered,
}) => (
  <div
    role="button"
    tabIndex={-1}
    onKeyDown={() => setOpenIndex(index)}
    onClick={() => {
      setOpenIndex(index)
    }}
    className="overflow-hidden"
  >
    <motion.div
      initial={{ scale: 1.1 }}
      onHoverStart={() => setHovered(index)}
      onHoverEnd={() => setHovered(-1)}
      animate={index === hovered ? { scale: 1 } : { scale: 1.1 }}
    >
      <Image
        className="w-100"
        image={item.recap.thumbnail}
        alt="De Tegelmeesters"
      />
    </motion.div>
  </div>
)

export default Projects
